import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import Container from '@material-ui/core/Container';
// import ReactLoading from 'react-loading';
import NumberFormat from 'react-number-format';
import "@fontsource/roboto";
import "@fontsource/roboto/700.css";
import { withRouter } from "react-router-dom";
import { gql, useMutation } from '@apollo/client';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
    header: {
      fontFamily: "roboto",
      fontWeight: "700",
    },
    paper: {
      marginTop: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 150px)',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(2, 0, 2),
    },
    intro: {
      margin: theme.spacing(2, 0, 1),
      fontSize: '14pt',
      color: "#6a7685",
    },
    small: {
      margin: theme.spacing(4, 0, 0),
      fontSize: '12pt',
      color: "#6a7685",
    },
  logo: {
    margin: theme.spacing(4, 0, 0),
    width: "70%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  }));

function Signin(props) {
    // Get the group ID off the URL path and set to empty string
    // if it's NULL.
    let { id } = useParams();
    if (id == null) {
      id = ""
    }
    
  function removeCookie(cookieName) {
    const expirationDate = new Date('2000-01-01'); // Set a past date
    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie = `${cookieName}=; ${expires}; path=/`;
  }

    // TODO: This seems lame, this should be removed at logout. Also need to
    // use the refresh token to keep sessions active.
    localStorage.removeItem('id'); 
    localStorage.removeItem('firstname'); 
    localStorage.removeItem('lastname'); 
    localStorage.removeItem('email'); 
    removeCookie('accessToken')
    removeCookie('refreshToken')
    localStorage.removeItem('nonce'); 
    localStorage.removeItem('isEnrolled'); 
    localStorage.removeItem('state');
    localStorage.removeItem('language');

    const classes = useStyles();

    const initialFormValue = {
        phoneNumber: ''
      }
    const [formValue, setFormValue] = useState(initialFormValue)

    const updateForm = (inputName, inputValue) => {
        setFormValue({
          ...formValue,
          [inputName]: inputValue
        })
      }

    const onChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        updateForm(name, value)
    }

    const SIGNIN = gql`
      mutation Signin($phone: String!, $group: String!) {
        signin(phone: $phone, group: $group) {
          nonce
        }
      }
    `;

    const [signin, { data, loading, error }] = useMutation(SIGNIN,  {
      onError: (err) => {
        console.log(err)
        for (let e of err.graphQLErrors) {
          switch (e.extensions.code) {
          // Aware Server sets code to INVALID_GROUP
          // when an invalid group is found.
          case 'INVALID_GROUP':
            console.log("Invalid group, please contact support@awarehealth.io");
            break;
          default:
            console.log("DEFAULT CASE");
          }
        }
      }});

    if (loading) return ""
    if (error) return `Submission error! ${error.message}`

    const handleSubmit = (e) => {
      e.preventDefault()
      signin({ variables: { phone: formValue.phoneNumber, group: id } })
  }

  if (data) {
    localStorage.setItem('nonce', data.signin.nonce); 
    localStorage.setItem('phone', formValue.phoneNumber); 
    props.history.push('/verify')
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
          <img className={classes.logo} src="/aware-logo-dark.png" alt="Logo"/>
          <div className={classes.intro}>
              Welcome! To get started please enter your phone number to verify your identity.
          </div>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <NumberFormat
            type="tel"
            label="Mobile number"
            style={{width: '100%'}}
            variant="outlined" 
            customInput={TextField}
            format="(###) ###-####"
            mask="_"
            name="phoneNumber"
            value={formValue.phoneNumber}
            onChange={onChange} />

          <div className={classes.small}>
            <p>By entering your phone number and tapping Next, you agree to the <a href="/tos">Terms, E-Sign Consent & Privacy Policy</a>.</p>
          </div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#5b92e5",
              }}
            >
              Next
            </Button>
        </form>
      </div>
      <Footer />
    </Container>


  )
}

export default withRouter(Signin)
